<template>
  <div class="vb-confirm">
    <h1 class="vb-confirm__title h2 text-center text-success mb-4">Complimenti! La tua prenotazione è confermata</h1>
    <div class="vb-confirm__print">
      <vb-button class="shadow-sm" @click="window.print()"
        :vb_options="{
          text: 'Stampa',
          size: 'sm',
          variant: 'light',
          icon: 'print',
        }"
      />
    </div>
    <p class="mb-5">
      Trovi di seguito un <strong>promemoria della tua prenotazione</strong> che puoi salvare o stampare.
      Ti abbiamo inviato lo stesso promemoria anche all'indirizzo <strong>{{voucher.email}}</strong>.<br>
      Alcuni giorni prima della partenza, all'indirizzo {{voucher.email}} ti invieremo la <strong>documentazione ufficiale di viaggio</strong>.<br><br>
      Per qualsiasi informazione aggiuntiva non esitare a contattarci ai contatti che trovi alla fine di questa pagina.
      Ricordati di avere a disposizione il <strong>Numero di prenotazione</strong> e il <strong>Booking ID VadoBay</strong>
      che trovi in questa pagina e che ti abbiamo inviato anche per email.
    </p>

    <h2 class="vb-heading h2">I dettagli della tua prenotazione</h2>
    <p>Ecco i dettagli del tuo pacchetto viaggio.</p>
    <b-row class="vb-confirm__wrap">
      <b-col md="12" lg="8" xl="9">
        <pratica
            :services="order_data.services"
            :passengers="order_data.compositions.passenger"
        />
      </b-col>
      <b-col md="12" lg="4" xl="3">
        <div class="vb-confirm__sidebar sticky-top">
          <h3 class="vb-heading h5 mb-4" v-html="'Riferimenti della prenotazione'" />

          <div class="vb-confirm__sidebar-item">
            <strong>Numero prenotazione (pratica):</strong>
            <span>{{voucher.numPratica}}</span>
          </div>

          <div class="vb-confirm__sidebar-item">
            <strong>Codice di conferma VadoBay:</strong>
            <span>{{voucher.confirmcode}}</span>
          </div>

          <div class="vb-confirm__sidebar-item">
            <strong>Prezzo:</strong>
            <span>{{voucher.live_price}}€</span>
          </div>
        </div>

      </b-col>
    </b-row>
    <my-alpitour class="section" />
  </div>
</template>

<script>
import Pratica from '@alpitour/components/blocks/pratica.vue'
import MyAlpitour from '@alpitour/components/blocks/my-alpitour'
export default {
  name: 'confirm-block',

  props: {
    order_data: Object,
    voucher: Object
  },

  components: {
    'pratica': Pratica,
    'my-alpitour': MyAlpitour,
  },

  data() {
    return {
    }
  },

  mounted() {
  },

  computed: {
  },

  methods: {
  }
}
</script>

<style lang="scss">

.vb-confirm {

  &__print {
    display: none;

    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: flex-end;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: $grid-gutter-width/3;
    }

    svg {
      path {
        fill: $vb-body-color;
      }
    }
  }

  &__sidebar {
    background-color: $white;
    padding: $grid-gutter-width/2;
    border-radius: $border-radius;
    top: $grid-gutter-width/2;
    margin-top: $grid-gutter-width;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $grid-gutter-width/3;

      span {
        font-size: $font-size-lg;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media print {

    &__wrap {
      // inverto l'ordine del blocco "Riferimenti prenotazione"
      & > div:first-child {
        order: 2;
      }
    }

    &__sidebar {
      margin-top: $grid-gutter-width/2;
      margin-bottom: $grid-gutter-width/2;
    }

    .vb-toggle {
      display: block !important;
    }

    @at-root .site-footer, .site-header__navigation, .site-header__user{
      display: none !important;
    }

    @at-root .site-header__logo {
      margin: 0 auto;
    }
  }
}

</style>