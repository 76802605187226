export const brands = {
  AWP: {
    name: "Alpitour World",
    logo: "Alpitour-World_positivo.png",
    show_in_homepage: false,
  },
  AL: {
    name: "Alpitour",
    logo: "logo-alpitour.svg",
    show_in_homepage: true,
  },
  EV: {
    name: "Eden viaggi",
    logo: "Eden-viaggi_color.png",
    show_in_homepage: true,
  },
  EG: {
    name: "Eden viaggi",
    logo: "Eden-viaggi_color.png",
    show_in_homepage: false,
  },
  BR: {
    name: "Bravo",
    logo: "MARCHIO BRAVO_POSITIVO.png",
    show_in_homepage: true,
  },
  TU: {
    name: "Turisanda",
    logo: "logo-turisanda.svg",
    show_in_homepage: true,
  },
  FR: {
    name: "Francorosso",
    logo: "logo-francorosso.svg",
    show_in_homepage: true,
  },
  EM: {
    name: "Made by Turisanda",
    logo: "Made_orizzontale.png",
    show_in_homepage: true,
  },
  PT: {
    name: "Presstour",
    logo: "Presstour_orizzontale_cmyk.png",
    show_in_homepage: true,
  }
}

export function get_brand(key) {
  if (brands[key] !== undefined) return brands[key]
  return {
    name: "Vacanze by VadoBay",
    logo: "default.svg",
    show_in_homepage: false,
  }
}