<template>
  <div class="vb-confirm-view">
    <b-container>

      <section class="section section--loader" v-if="loading">
        <loader size="large" :show_timing_slot="true" />
      </section>

      <section class="section" v-if="!loading">
        <confirm
            :order_data="confirm.orderData"
            :voucher="confirm.holidayVoucher"
        />
      </section>

    </b-container>
  </div>
</template>

<script>
import { service_handler } from '@src/services'
import { decode_parameters } from '@src/utilities'
import Confirm from '@alpitour/components/views/content-blocks/confirm.vue'
export default {
  name: 'confirm-view',

  props: {
    holiday_voucher_id: String,
    confirm_code: String,
  },

  components: {
    'confirm': Confirm,
  },

  data() {
    return {
      loading: true,
      confirm: {}
    }
  },

  mounted() {
    if (Object.keys(this.$route.params).length) {
      this.log('IF PARAMS')
      this.params = {
        ...this.params,
        ...this.$route.params,
      }
    }
    if (Object.keys(this.$route.query).length) {
      this.log('IF QUERY')
      let params = decode_parameters(this.$route.query.params)
      this.params = {
        ...this.params,
        ...params,
      }
    }

    this.get_confirm()
  },

  methods:
  {
    get_confirm() {
      service_handler(
        'confirm',
        { holidayVoucherId: this.holiday_voucher_id, confirmcode: this.confirm_code },
      )
        .then(
          (success) => {
            this.log('[confirm.vue] - success')
            this.log(success.data)

            this.confirm = success.data
            this.loading = false

          },
          (error) => {
            this.log('[confirm.vue] - error')
            this.log(error.response)

            this.confirm = {}
            this.loading = false

          }
        )

    },
  }
}
</script>