export const carriers = {
  NO: {
    name: "NEOS",
  },
  AP: {
    name: "ALBASTAR",
  },
  AT: {
    name: "RAM",
  },
  AZ: {
    name: "ITA AIRWAYS",
  },
  BJ: {
    name: "NOUVEL AIR",
  },
  E5: {
    name: "AIR ARABIA EGYPT",
  },
  EN: {
    name: "AIR DOLOMITI",
  },
  H6: {
    name: "BULGARIAN AIR",
  },
  HZ: {
    name: "AIR HORIZONT",
  },
  MS: {
    name: "EGYPTAIR",
  },
  MV: {
    name: "AIR MEDITERRANEAN",
  },
  QR: {
    name: "QATAR AIRWAYS",
  },
  SM: {
    name: "AIR CAIRO",
  },
  TU: {
    name: "TUNIS AIR",
  },
  U2: {
    name: "EASYJET",
  },
  V7: {
    name: "VOLOTEA",
  },
  VY: {
    name: "VUELING",
  },
  WY: {
    name: "OMAN AIR",
  },
  YW: {
    name: "AIR NOSTRUM",
  },
  EY: {
    name: "ETIHAD",
  },
  NT: {
    name: "BINTER",
  },
  FH: {
    name: "FREEBIRD",
  },
  CY: {
    name: "CYPRUS AIRWAYS",
  },
  EA: {
    name: "ELECTRA",
  },
  W6: {
    name: "WIZZAIR",
  },
  TK: {
    name: "TURKISH AIRLINES",
  },
  RJ: {
    name: "ROYAL JORDANIAN",
  },
  EI: {
    name: "AER LINGUS",
  },
  BA: {
    name: "BRITISH AIRWAYS",
  },
  OS: {
    name: "AUSTRIAN AIRLINES",
  },
  ZB: {
    name: "AIR ALBANIA",
  },
  AC: {
    name: "AIR CANADA",
  },
  PC: {
    name: "PEGASUS",
  }
}

export function get_carriers(key) {
  if (carriers[key] !== undefined) return carriers[key]
  return {
    name: key, // mal che vada mostro il codice iata
  }
}